import React from 'react';
import { motion } from 'framer-motion';
import { ChevronRight, Users, Code, ShieldCheck } from 'lucide-react';

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div 
    className="bg-white p-6 rounded-lg shadow-lg"
    variants={fadeIn}
  >
    <Icon className="w-12 h-12 text-sky-600 mb-4" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const Button = ({ children, primary, href }) => (
  <a
    href={href}
    className={`inline-flex items-center px-6 py-3 rounded-full text-sm font-medium transition-colors ${
      primary
        ? 'bg-sky-600 text-white hover:bg-sky-700'
        : 'bg-white text-sky-600 hover:bg-sky-50'
    }`}
  >
    {children}
    <ChevronRight className="ml-2 h-4 w-4" />
  </a>
);

export default function EvolveLandingPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-sky-100 to-blue-100">
      <header className="py-6">
        <div className="container mx-auto px-6">
          <h1 className="text-3xl font-bold text-sky-900">Evolve RP</h1>
        </div>
      </header>

      <main>
        <section className="py-20">
          <div className="container mx-auto px-6 text-center">
            <motion.h2 
              className="text-5xl font-bold text-sky-900 mb-4"
              variants={fadeIn}
              initial="initial"
              animate="animate"
            >
              Welcome to Evolve Server
            </motion.h2>
            <motion.p 
              className="text-xl text-sky-700 mb-8"
              variants={fadeIn}
              initial="initial"
              animate="animate"
              transition={{ delay: 0.2 }}
            >
              An evolution in the Moroccan RP scene.<br />
              Evolve RP is the Right Choice. Be Ready.
            </motion.p>
            <motion.div 
              className="flex justify-center space-x-4"
              variants={fadeIn}
              initial="initial"
              animate="animate"
              transition={{ delay: 0.4 }}
            >
              <Button primary href="index.html">Connect</Button>
              <Button href="https://apply.evolveroleplay.net">Application Website</Button>
              <Button href="https://discord.gg/duX7Vb5h4H">Discord Server</Button>
            </motion.div>
          </div>
        </section>

        <section className="py-20 bg-white">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center text-sky-900 mb-12">What Evolve has to offer</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <FeatureCard
                icon={Users}
                title="Community Driven"
                description="Our players make the community thrive with great stories both in-character and out-of-character."
              />
              <FeatureCard
                icon={Code}
                title="Active Development"
                description="Our developers work tirelessly to ensure fresh experiences and quality improvements every day."
              />
              <FeatureCard
                icon={ShieldCheck}
                title="Reliable Staff"
                description="Our dedicated staff is always available to help, from basic questions to handling reports efficiently."
              />
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-sky-900 text-white py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; Evolve Server 2024 | Made with ❤️ by Evolve</p>
        </div>
      </footer>
    </div>
  );
}